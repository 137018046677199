import React from 'react';
import galleryBanner from '../../assets/images/gallery/gallery-banner.png';
import WrapperStyle from './GalleryBanner.style';
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const GalleryBanner = () => {
    return (
        <WrapperStyle>
        <section className="solutions-area">
            <div className="container" >
                <div className="banner">
                    <StaticImage src="../../assets/images/gallery/gallery-banner.png" className="banner" alt="about" />
                </div>
            </div>
        </section>
        </WrapperStyle>
    )
};

export default GalleryBanner;