import styled from 'styled-components';
const GalleryBannerStyle = styled.div` 
    .container {
      position: relative;
      height: 500px;
    }
    .banner {
      width: 100%;
      position: absolute;
       z-index: 1; 
       margin: 70px auto -110px;
       float: left;
    }
     
    @media only screen and (min-width: 767px) and (max-width: 1356px)  {
      .container {
          height: 470px;
        }

    }
    @media only screen and (max-width: 1199px) and (min-width: 992px){
        .container {
          height: 425px;
        }
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      .container {
          height: 355px;
        }
    }
   
    @media only screen and (max-width: 767px)  and (min-width: 420px)  {
      .container {
        height: 300px;
      }
    }
   
    @media only screen and (max-width: 420px) {
    
      .container {
        height: 213px;
      }
      
      img.banner { 
         margin: 100px auto -110px;
      }
       
    }
   
`;

export default GalleryBannerStyle