import React from 'react';

import shape from '../../assets/images/shape/shape1.svg';
import InstagramIcon from '../../assets/images/gallery/instagram-logo.png';
import TwitterIcon from '../../assets/images/gallery/twitter-logo.png';
import {navigate} from 'gatsby';

const TestimonialsStyleTwo = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-fafafb" style={{zIndex:'initial'}}>
            <div className="container">
                <div className="section-title" style={{marginTop: '50px'}}>
                    <h2>Instagram・Twitterに対応</h2>
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>*/}
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={InstagramIcon} className="shadow-sm" alt="about" />
                            <div className="about-content">
                                <div className="content">
                                    <p>Instgramの公式APIに対応。指定した「ハッシュタグ」または「ハッシュタグ＋メンションタグ」のついた写真を自動収集できます。複数枚画像や動画にも対応しております。</p>
                                    <ul className="about-list mb-0">
                                        <li style={{width: '100%'}}>
                                            <i className="flaticon-tick"></i>
                                            #ハッシュタグ
                                        </li>
                                        <li style={{width: '100%'}}>
                                            <i className="flaticon-tick"></i>
                                            #ハッシュタグ＋@メンションタグ
                                        </li>
                                    </ul>
                                    <div style={{paddingTop:'30px'}}>
                                        <button type="button" class="btn btn-primary btn-lg" onClick={()=> navigate('/gallery/instagram-example')}>サンプルを見る</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-box">
                            <img src={TwitterIcon} className="shadow-sm" alt="about" />
                            <div className="about-content">
                                <div className="content">
                                    <p>Tiwtterの公式API対応。指定したハッシュタグをANDやOR検索で条件を指定して自動取得できます。複数枚画像や動画にも対応しております。<br/></p>
                                    <ul className="about-list mb-0">
                                        <li style={{width: '100%'}}>
                                            <i className="flaticon-tick"></i>
                                            #ハッシュタグ
                                        </li>
                                    </ul>

                                    <div style={{paddingTop:'30px'}}>
									  <button type="button" class="btn btn-primary btn-lg" onClick={()=> navigate('/gallery/twitter-example')}>サンプルを見る</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="about" />
            </div>
        </div>
    )
};

export default TestimonialsStyleTwo