import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import galleryCustomize from '../assets/images/gallery/gallery-customize.png';
import galleryImport from '../assets/images/gallery/gallery-import.png';
import galleryVote from '../assets/images/gallery/gallery-vote.png';

import Providers from '../components/Gallery/Providers'
import StartProject from '../components/Common/StartProject';
import GalleryBanner from '../components/Gallery/GalleryBanner'
import SEO from '../components/App/SEO';
import galleryMetaImage from '../assets/images/gallery/gallery-meta-image.jpg';
import Image from '../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const Gallery = () => {

    return (
        <Layout>
          <SEO
            title="ハッシュタグキャンペーン｜フォトコンテスト｜UGCコンテンツを自動収集"
            desc="個人から中小企業・大企業まで、Twitter・Instagramのハッシュタグコンテンツを収集します。投票機能でフォトコンテストも簡単に開催"
            banner={galleryMetaImage}
            pathname={'/gallery'}
            type={'Article'}
            shortName="ハッシュタグキャンペーン"
            createdAt="2021-03-02"
          />
            <Navbar />
            <PageBanner
                pageTitle="ギャラリー"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="ギャラリー"
            />
            <GalleryBanner />
            <Providers />
          <div className="pb-100"></div>
          <div className="our-mission-area pb-100">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="our-mission-content">
                    <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <img src={starIcon} alt="icon" /> Services*/}
                                    {/*</span>*/}

                      <h2>強力なカスタマイズ</h2>
                      <p>ポップアップの表示から背景色の設定を細かく設定が可能です。ブランドや企業イメージを損なうことなく、デザインをカスタマイズできます。</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="our-mission-image">
                    <StaticImage src="../assets/images/gallery/gallery-customize.png" alt="service" style={{width: '450px', margin:'auto'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-area pb-100">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="our-mission-image">
                    <StaticImage src="../assets/images/gallery/gallery-import.png" alt="service"  style={{width: '450px', margin:'auto'}}/>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="about-content">
                    <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <img src={starIcon} alt="icon" /> Services*/}
                                    {/*</span>*/}

                      <h2>どこでもギャラリーに</h2>
                      <p>カスタマイズしたギャラリーは、レスポンシブ対応はもちろん、ホームページ、ECサイト、ブログなど、どこでも設置できます。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-mission-area pb-100">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="our-mission-content">
                    <div className="content">
                      {/*<span className="sub-title">*/}
                      {/*    <img src={starIcon} alt="icon" /> Services*/}
                      {/*</span>*/}

                      <h2>フォトコンテストを開催</h2>
                      <p>投票機能を使用することで、ギャラリーを簡単にフォトコンテストとして使用できます。</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="our-mission-image">
                    <StaticImage src="../assets/images/gallery/gallery-vote.png" alt="service" style={{width: '450px', margin:'auto'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StartProject/>
            <Footer />
        </Layout>
    );
};

export default Gallery